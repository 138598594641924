import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const wrapRootElement = ({ element }) => {
  const googleCaptchaId = '6Le4sdcZAAAAANpHA5dE1MLPi8K-KVYyoG1E1ecF'

  return (
    <GoogleReCaptchaProvider scriptProps={{async: true}} reCaptchaKey={googleCaptchaId}>
      {element}
    </GoogleReCaptchaProvider>
  )
}