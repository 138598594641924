module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-WNRXLKX","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer","defaultDataLayer":{"platform":"gatsby"}},"environments":["production","staging"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SeenThis","short_name":"SeenThis","start_url":"/","background_color":"#1a1a1a","theme_color":"#1a1a1a","display":"standalone","icon":"src/assets/logos/favicon_smiley.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2a11c83434e22edd3cddc583dc767720"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
